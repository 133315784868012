<template>
    <div class="notice-channel-box">
        <h2>{{ name }}</h2>
        <div class="aes-items">
            <template v-for="(item, key) in items">
                <AesItem :item="item" :key="key" />
            </template>
        </div>
    </div>
</template>
<script>
import AesItem from "./aes-item.vue";

export default {
    props: {
        name: {
            type: String
        },
        items: {
            type: Array
        }
    },
    components: {
        AesItem,
    },
}

</script>
<style scoped>
.notice-channel-box {
    margin-bottom: 20px;
}
.aes-items {
    display: flex;
    width: 100%;
    height: 100%;
    white-space: break-spaces;
    flex-wrap: wrap;
}
</style>